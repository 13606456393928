.PreviewContainer {
  height: 14vh;
  width: 100vw;

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0%;

  padding-bottom: 2vh;
}

.PreviewText {
  position: relative;
  left: 2vw;
}

p {
  color: rgb(0, 0, 0);
  font-size: 4vw;
  font-weight: light;
}

.PreviewTitle {
  color: rgb(51, 122, 183);
  font-weight: bold;
  font-size: 6vw;
}

.PreviewTextItems {
  height: 14vh;
  width: 100vw;
}
