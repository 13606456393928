/* display viewport too large message if too large */
@media (max-width: 449px) {
  .HomeDisplayMessage {
    display: none;
  }
}

/* dont display main conent if viewport is too large */
@media (min-width: 450px) {
  .HomeMainContent {
    display: none;
  }
}

.HomeMainContent {
  /* add some top padding for header */
  padding-top: 3vw;
}

.Home {
  overflow-x: hidden;
  overflow-y: hidden;
}
