/* display viewport too large message if too large */
@media (max-width: 449px) {
  .ParadeDisplayMessage {
    display: none;
  }
}

/* dont display main conent if viewport is too large */
@media (min-width: 450px) {
  .ParadeMainContent {
    display: none;
  }
}

.ParadeMainContent {
  /* add some top padding for header */
  padding-top: 3vw;

  overflow-x: hidden;
}

.Photo {
  width: 100vw;
  height: auto;

  padding-top: 5vh;
  padding-bottom: 5vh;

  background-color: black;
}

.PhotoContainer {
  padding-top: 7vh;
}

.ParadeGalleryDate {
  color: black;
  font-size: 3vw;
  font-weight: 500;

  position: absolute;
  left: 3vw;
}

.ParadeGalleryPhotoNumber {
  color: black;
  font-size: 3vw;
  font-weight: 500;

  position: absolute;
  right: 3vw;
}

.PhotoLowerDataContainer {
  height: 5vh;
  width: 100vw;
  background-color: white;

  display: flex;
  align-items: center;
}

.LowerParadeMainContainer {
  padding-top: 3vh;
  padding-bottom: 3vh;
  height: 3vh;
  width: 100vw;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  border-top: rgb(175, 175, 175) solid 0.5px;
}

.ChangePhotoButton {
  font-size: 4vw;
  font-weight: 500;

  border: none;
  background-color: white;
}

.PreviousPhotoButton {
  padding-right: 5vw;
}

.NextPhotoButton {
  padding-left: 5vw;
}

.ParadePageTitle {
  width: 70vw;
}

.ParadeGalleryLocation {
  font-size: 4vw;
  font-weight: 500;
}

.ParadeTopPageContainer {
  width: 100vw;
  height: 5vh;

  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
}

.GoHome {
  font-size: 5vw;
  text-decoration: none;

  position: absolute;
  top: 70vh;

  padding-left: 5vw;
}

.ParadeIconLogo {
  /* size */
  width: 8vw;
  height: auto;
}

.IconEnlarge {
  /* size */
  width: 4vw;
  height: 4vw;
}

.HomePageIcon {
  width: 8vw;
  height: 8vw;
  border: none;
  background-color: white;

  position: absolute;
  left: 5vw;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ExpandPhoto {
  width: 4vw;
  height: 4vw;
  border: none;
  background-color: rgb(0, 0, 0);

  position: relative;
  left: 92vw;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ExpandPhotoContainer {
  width: 100vw;
  height: 10vw;
  position: absolute;
  display: flex;
  align-items: center;
}

.ParadeHomeAndExpand {
  padding: 5vw;
  display: grid;
  justify-content: center;
  align-items: center;
}

.ParadeMainContentFullScreen {
  background-color: black;
  color: white;
}

/* full screen styling */
.PhotoContainerFullScreen {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  background-color: black;
}

.ClosePhoto {
  width: 6vw;
  height: 6vw;
  border: none;
  background-color: black;

  position: absolute;
  right: 5vw;
  top: 5vw;

  display: flex;
  justify-content: center;
  align-items: center;
}

.CloseIcon {
  width: 6vw;
  height: auto;
}

.PageTitleButton {
  background-color: white;
  border: none;
}
