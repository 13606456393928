.HeaderFooter {
  /* size */
  height: 8vw;
  width: 100vw;

  background-color: rgb(0, 0, 0);

  display: flex;
  align-items: center; /* vertical centering */

  background-color: rgba(0, 0, 0, 0.01);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

h1 {
  position: absolute;
  left: 2vw;
  font-size: 3vw;
}
