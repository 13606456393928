.Header {
  /* size */
  height: 12vw;
  width: 100vw;

  display: flex;
  align-items: center; /* vertical centering */
  justify-content: center;
}

.IconLogo {
  width: 100vw;
  height: 12vw;

  position: absolute;

  display: flex;
  align-items: center; /* vertical centering */
}

.TextLogoImg {
  /* size */
  width: 40vw;
  height: auto;
}

.IconLogoImg {
  /* size */
  width: 8vw;
  height: auto;

  position: absolute;
  left: 4vw;
}
